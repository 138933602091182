import NavWrap from './NavWrap';
import HeaderTop from './HeaderTop';
import HeaderBottom from './HeaderBottom';
import useFixedHook from '../hooks/useFixedHook';

const Navbar = () => {
    const {isFixed,headRef} = useFixedHook()

    return (
        <NavWrap >
            <HeaderTop />
            <HeaderBottom isFixed={isFixed} headRef={headRef}/>
        </NavWrap>
    )
}

export default Navbar