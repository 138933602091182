import React from 'react'
import { modalHead, modalHeadButton, modalHeadText } from '../Styles/Styles'
import Button from './Button'

export default function ContactHead({ data, contactFormModal }) {
    return (
        <div className={modalHead}>
            <Button
                styles={modalHeadButton}
                onClick={contactFormModal}
                text={data.cancelIcon}
            />
            <h3 className={modalHeadText}>
                {data.head}
            </h3>
        </div>
    )
}
