import React from 'react'
import HeaderStruct from './HeaderStruct'
import { container, dataAosDuration, dataAosFadeUp, dataAosRep, layoutH1, layoutH1Wrap, layoutWrap, layoutWrapFlex } from '../Styles/Styles'

export default function Layout({ children, data }) {

    return (
        <div className={layoutWrap}>
            <div className={container}>
                <div className={layoutH1Wrap}>
                    <h1 data-aos={dataAosFadeUp} data-aos-duration={dataAosDuration} data-aos-once={dataAosRep} className={layoutH1}>
                        {data.map(item => {
                            return <HeaderStruct key={item.id} item={item} />
                        })}
                    </h1>
                </div>
                <div className={layoutWrapFlex}>
                    {children}
                </div>
            </div>
        </div>
    )
}
