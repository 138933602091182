import React from 'react'
import { dataAosDuration, dataAosFadeUp, footerListLayoutH1Styles, footerListLayoutStyles } from '../Styles/Styles';
import FooterLists from './FooterLists';

export default function FooterListsLayout({ listData }) {
    return (
        <div className={footerListLayoutStyles}>
            <h1 className={footerListLayoutH1Styles}>
                {listData.title}
            </h1>
            <div>
                <ul data-aos={dataAosFadeUp} data-aos-duration={dataAosDuration} className='custom-fade-up'>
                    {listData.lists.map((item, index) => {
                        return <FooterLists key={item.id} index={index} list={item} id={item.id} />
                    })}
                </ul>
            </div>
        </div>
    )
}
