import React, { useContext, useEffect, useState } from 'react'
import Courses from '../components/Courses'
import Enroll from '../components/Enroll'
import FundamentalCourses from '../components/FundamentalCourses'
import Navbar from '../components/Navbar'
import SlickSlider from '../components/SlickSlider'
import {
    slickSliderData,
    // footerData,
    contactFormData,
    moduleHeaderData,
    enrollData,
    enrollData2,
    enrollData3,
    counterData,
    overlayData,
} from '../Data/SkillupData'
import Footer from '../components/Footer'
import CounterArea from '../components/CounterArea'
import ContactModal from '../components/ContactModal'
import ScrollWrap from '../components/ScrollWrap'
import Anchors from '../components/Anchors'
import Overlay from '../components/Overlay'
import { Context } from '../Context/Context'


export default function SkillUp() {
    const { state } = useContext(Context);
    return (
        <>
            <Anchors />
            <ScrollWrap />
            <ContactModal data={contactFormData} />
            <Navbar />
            <SlickSlider
                data={state.sliderData}
            />
            <Courses
                data={state.aboutUsData}
            />
            <FundamentalCourses
                data={state.skillUpData}
                layoutData={moduleHeaderData}
                name='Module1'
            />
            <Enroll data={enrollData} />
            <FundamentalCourses
                data={state.skillUpData}
                layoutData={moduleHeaderData}
                name='Module2'
            />
            <CounterArea data={counterData} />
            <Enroll data={enrollData2} />
            <FundamentalCourses
                data={state.skillUpData}
                layoutData={moduleHeaderData}
                name='Module3'
            />
            <Enroll data={enrollData3} />
            <Footer data={state.footerData} />
            <Overlay data={overlayData} />
        </>
    )
}
