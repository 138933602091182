import { useState } from 'react'

export const useDateHook = () => {
    const [date, setDate] = useState(Date.now());
    const handleArrowClick = () => {
        setDate(Date.now());
    };

    return { date, handleArrowClick }
}
