
export const reducer = (state, { type, payload }) => {
    switch (type) {
        case "UPDATE_OVERLAY_STATE":
            console.log(payload);
            return {
                ...state,
                overlayState: {
                    name: payload.name
                }
            }
        case "TOGGLE_MODAL":
            return {
                ...state,
                showModal: !state.showModal
            }
        case "HANDLE_CHANGE":
            const { name, value } = payload;
            return {
                ...state,
                [name]: value
            }
        case "TOGGLE_OVERLAY":
            return {
                ...state,
                overlayToggle: !state.overlayToggle
            }
        case "CLEAR_QUERY_STATE":
            return {
                ...state,
                name: "",
                email: "",
                mobile: "",
            }
        case "SET_SKILLUP_DATA":
            return {
                ...state,
                skillUpData: payload
            }
        case "SET_ABOUTUS_DATA":
            return {
                ...state,
                aboutUsData: payload
            }
        case "SET_SLIDER_DATA":
            return {
                ...state,
                sliderData: payload
            }
        case "SET_FOOTER_DATA":
            return {
                ...state,
                footerData: payload,
            }
        case "SET_RELATED_COURSE_DATA":
            return {
                ...state,
                relatedCourseData: payload,
            }
        default:
            return state
    }
}
