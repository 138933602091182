import  { useContext } from 'react'
import { Context } from '../Context/Context';

export default function useOverlay() {
    const { dispatch } = useContext(Context);

    const handleOverlayToggle = (e) => {
        dispatch({ type: 'TOGGLE_OVERLAY' });
    }
    return { handleOverlayToggle }
}
