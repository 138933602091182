import React from 'react'
import { headContact, headIcons } from '../Data/SkillupData'
import { navTopBg, navTopFlex, navTopIcon, navTopLinkOuter, navTopOuter, navTopPara, navTopUl } from '../Styles/Styles'
import HeaderLinks from './HeaderLinks'

export default function HeaderTop() {
    return (
        <div className={navTopBg}>
            <div className={navTopFlex}>
                <div className={navTopOuter}>
                    <p className={navTopPara}>
                        <i className={navTopIcon}></i>
                        {headContact}
                    </p>
                </div>
                <div className={navTopLinkOuter}>
                    <ul className={navTopUl}>
                        {headIcons.map((item, index) => {
                            return <HeaderLinks key={item.id} index={index} to={item.to} icon={item.icon} styles={item.styles} />
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
