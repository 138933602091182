import React, { useContext } from 'react'
import { Routes, Route,useLocation } from 'react-router'
import SkillUp from '../Pages/SkillUp'
import Page from '../Pages/Page'
import { Context } from '../Context/Context';
import useFetchData from '../hooks/useFetchData';

export default function Links() {
    const { state } = useContext(Context);
    const location = useLocation();
    useFetchData('/auth/getSkillUpData','SET_SKILLUP_DATA')
    useFetchData('/auth/getAboutUsData','SET_ABOUTUS_DATA')
    useFetchData('/auth/getSliderData','SET_SLIDER_DATA')
    useFetchData('/auth/getFooterSectionData','SET_FOOTER_DATA')
    useFetchData('/auth/getRelatedCourseData','SET_RELATED_COURSE_DATA')
    return (
        <>
            <Routes >
                <Route path="/" element={<SkillUp />} />
                <Route path={`${location.pathname}`} element={<Page />} />
            </Routes>
        </>
    )
}
