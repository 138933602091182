import React from 'react'
import {
    modalDelay,
    modalInnerStyles,
    modalLayoutStyles,
    modalOuterStyles
} from '../Styles/Styles'

export default function ContactModalLayout({ children }) {
    return (
        <div className={modalLayoutStyles}>
            <div className={modalOuterStyles} data-aos-duration={modalDelay}>
                <div className={modalInnerStyles}>
                    {children}
                </div>
            </div>
        </div>

    )
}
