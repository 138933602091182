import React, { createContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { reducer } from './reducer';

const initialState = {
    showModal: false,
    name: '',
    email: '',
    mobile: '',
    query: false,
    overlayToggle: false,
    overlayState: {
        name: '',
    },
    skillUpData: [],
    aboutUsData: [],
    sliderData: [],
    footerData: [],
    relatedCourseData: [],
}

export const Context = createContext()

export function ContextProvider({ children }) {

    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate();
    const location = useLocation()
    console.log(state);
    const handleNavBtnClick = () => {
        navigate('/pages/form');
    }

    useEffect(() => {
        if (location.pathname === '/pages/FundamentalModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Fundamental Modules' } })
        } else if (location.pathname === '/pages/BusinessModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Business Modules' } })
        } else if (location.pathname === '/pages/WebDesigningModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Web Designing Modules' } })
        } else if (location.pathname === '/pages/PlacementModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Placement Modules' } })
        } else if (location.pathname === '/pages/InfrastructureModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Infrastructure Modules' } })
        } else if (location.pathname === '/pages/TestingModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Testing Modules'} })
        } else if (location.pathname === '/pages/DataScience&Visualization') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Data Science & Visualization'} })
        } else if (location.pathname === '/pages/CyberSecurity&BitcoinModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Cyber Security'} })
        } else if (location.pathname === '/pages/TrendingModules') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'Trending Modules'} })
        } else if (location.pathname === '/') {
            dispatch({ type: 'UPDATE_OVERLAY_STATE', payload: { name: 'home' } });
        }

    }, [location.pathname])
    return (
        <Context.Provider
            value={{ state, dispatch, handleNavBtnClick }}
        >
            {children}
        </Context.Provider>
    )
}
