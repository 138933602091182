import React from 'react'
import { dataAosDuration500, dataAosFadeUp, overlayContentStyles } from '../Styles/Styles';
import OverlayImage from './OverlayImage'
import OverlayLayout from './OverlayLayout'
import OverlayLinks from './OverlayLinks'

export default function OverlayContent({ data, handleOverlayToggle }) {
    console.log(data.pageNames);

    return (
        <OverlayLayout>
            <OverlayImage logo={data.logo} />
            <div className={overlayContentStyles} data-aos={dataAosFadeUp} data-aos-duration={dataAosDuration500}>
                {data.pageNames.map((item, id) => (
                    <OverlayLinks link={item.link} id={id} handleOverlayToggle={handleOverlayToggle} />
                ))}
            </div>
        </OverlayLayout>
    )
}
