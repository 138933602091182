import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import {
    buttonStyles,
    buttonStyles1,
    flex,
    navBottomWrap,
    navButtonWrap,
    navIconsStyles,
    navIconWrap,
    navLogo,
    navLogoWrap,
    navSloganH5,
    navSloganWrap,
    navSlogaSpan

} from '../Styles/Styles'
import { Context } from '../Context/Context'
import useOverlay from '../hooks/useOverlay'
import { headerLogo, loginButton } from '../Data/SkillupData'

export default function HeaderBottom({ isFixed, headRef }) {
    const { handleNavBtnClick } = useContext(Context)
    const { handleOverlayToggle } = useOverlay();
    const { to, logo, name, text1, text2, span } = headerLogo

    return (
        <div className={`${isFixed ? 'sticky ' : ''} `} ref={headRef} style={{ transition: '0.6s' }}>
            <div className={navBottomWrap}>
                <div className={navLogoWrap}>
                    <Link to={to}>
                        <img src={logo} alt={name} className={navLogo} />
                    </Link>
                    <div className={navSloganWrap}>
                        <h5 className={navSloganH5}>{text1}</h5>
                        <span className={navSlogaSpan}>{span}</span>
                        <h5 className={navSloganH5}>{text2}</h5>
                    </div>
                </div>
                <div className={flex}>
                    <div className={navButtonWrap}>
                        <Button styles={`${buttonStyles}${buttonStyles1}`} onClick={handleNavBtnClick} text={loginButton} />
                    </div>
                    <div className={navIconWrap}>
                        <span className={navIconsStyles} onClick={() => handleOverlayToggle()}>☰</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
