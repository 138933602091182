import React from 'react'
import { Link } from 'react-router-dom'
import { overlayH4Styles, overlayLinksStyles, overlayLinksWrapStyles } from '../Styles/Styles'

export default function OverlayLinks({ link, id, handleOverlayToggle }) {

    return (
        <div className={overlayLinksWrapStyles}>
            <h4 className={overlayH4Styles}>
                <Link key={id} to={`/pages/${link}`}>
                    <div className={overlayLinksStyles} onClick={(e) => handleOverlayToggle(e)}
                    >
                        {link}
                    </div>
                </Link>
            </h4>
        </div>
    )
}
