import React, { useContext } from 'react'
import Anchors from '../components/Anchors'
import ContactModal from '../components/ContactModal'
import CounterArea from '../components/CounterArea'
import Enroll from '../components/Enroll'
import Footer from '../components/Footer'
import FundamentalCourses from '../components/FundamentalCourses'
import Navbar from '../components/Navbar'
import Overlay from '../components/Overlay'
import RelatedCourses from '../components/RelatedCourses'
import ScrollWrap from '../components/ScrollWrap'
import SlickSlider from '../components/SlickSlider'
import { Context } from '../Context/Context'
import { contactFormData, counterData, enrollData,  moduleHeaderData, overlayData, relatedModuleHeaderData, relatedModulesData } from '../Data/SkillupData'

export default function Page() {
    const { state } = useContext(Context)
    console.log(state);
    return (
        <>
            <Anchors />
            <ScrollWrap />
            <ContactModal data={contactFormData} />
            <Navbar />
            <SlickSlider
                data={state.sliderData}
            />
            <FundamentalCourses
                data={state.skillUpData}
                layoutData={moduleHeaderData}
                name={state.overlayState.name}
            />
            <CounterArea data={counterData} />
            <Enroll data={enrollData} />
            <RelatedCourses data={state.relatedCourseData} layoutData={relatedModuleHeaderData} />
            <Footer data={state.footerData} />
            <Overlay data={overlayData} />
        </>
    )
}

