import img1 from "../img/slider/slider_bg_1.jpg";
import img2 from "../img/slider/slider_bg_2.jpg";
import logoImg from '../img/logo/logo.png';
import logoImg1 from '../img/PROFILE_SKILLUP_LOGO-02.jpg';
import fundamentalImg from '../img/modules/fundamental.jpg';
import webDesign from '../img/modules/webdesign.jpg'
import bussiness from '../img/modules/business.jpg'
import placement from '../img/modules/placement.jpg'
import cloudcomputing from '../img/modules/cloudcomputing.jpg'
import testing from '../img/modules/testing.jpg'
import counter from "../img/counter/counter_icon1.png"
import counter2 from "../img/counter/counter_icon2.png"
import counter3 from "../img/counter/counter_icon3.png"
import counter4 from "../img/counter/counter_icon4.png"
import course20 from '../img/courses/course20.jpg'
import course21 from '../img/courses/course21.jpg'
import course22 from '../img/courses/course22.jpg'
import course23 from '../img/courses/course23.jpg'
import course24 from '../img/courses/course24.jpg'
import * as uuid from 'uuid'

export const contactAnchorData = { text: 'call now', href: '+917208581498' }
export const loginAnchorData = { href: '+917208581498', text: 'login' }

//Icon

export const IconData = { img: logoImg1, title: 'skillupitacademy', name: 'logo' }

// Navbar Data

export const headIcons = [
    { id: uuid.v4(), icon: 'ti ti-facebook', to: '/', styles: '' },
    { id: uuid.v4(), icon: 'ti ti-google', to: '/', styles: 'before:absolute before:content-[""] before:h-[12px] before:w-[1px] before:bg-[#fff] before:left-0 before:top-[2px]' },
]
export const headContact = '7208581498'
export const loginButton = 'login'
export const headerLogo = {
    to: '/',
    logo: logoImg,
    name: 'logo',
    text1: 'An Internship Hub',
    text2: 'Microsoft Official Partner',
    span: ' | '
}

export const slickSliderData = [
    { id: uuid.v4(), image: img1, title: 'An Internship Hub' },
    { id: uuid.v4(), image: img2, title: 'Microsoft Official Partner' }
]

export const slickBgBtn = 'enquire now'

export const slickBgHeaderData = [
    { text: 'Skill', color: 'text-pink' },
    { text: 'Up', color: 'text-blue' },
    { text: ' IT ', color: 'text-pink' },
    { text: 'Academy', color: 'text-blue' },
]

//Section Header data

export const aboutUsHeaderData = [
    { id: uuid.v4(), text: 'About', color: 'text-pink' },
    { id: uuid.v4(), text: ' Us', color: 'text-blue' },
]
export const moduleHeaderData = [
    { id: uuid.v4(), text: 'Our', color: 'text-pink' },
    { id: uuid.v4(), text: ' Modules', color: 'text-blue' },
]
export const relatedModuleHeaderData = [
    { id: uuid.v4(), text: 'Related', color: 'text-pink' },
    { id: uuid.v4(), text: ' Modules', color: 'text-blue' },
]

// AboutUs data

export const aboutUsCardsData = [
    {
        id: uuid.v4(),
        h1Span: "W",
        h1: "e at SkillUp IT Academy ensure that students are not only getting trained, educated in IT subjects or programming languages but also getting skilled so that they can explore knowledge and create new opportunities, innovation in this competitive and dynamic world."
    },
    {
        id: uuid.v4(),
        h1Span: "W",
        h1: "e at SkillUp IT Academy work on to build platform where skill and ability can match up to build bright future of current and future generations. We believe in training where we train students to explore knowledge rather than settle on the existing skills they have."
    },
    {
        id: uuid.v4(),
        h1Span: "T",
        h1: "hrough our extensive feedback-oriented training, practice, assignments, projects we ensure to achieve our company's objectives, values, mission and belief towards kids, students, professionals who aim IT as their dream career and prospects."
    },
]

//Fundamental Courses Data

export const fundamentalCoursesData = [
    {
        id: uuid.v4(),
        img: fundamentalImg,
        name: 'Fundamental Modules',
        coursesHeading: 'Fundamental Module',
        core: 'Core Programming',
        courseDis: ' Success is the natural consequence of consistently applying the basic fundamentals.',
        bgColor: '#7665ff',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
    {
        id: uuid.v4(),
        img: webDesign,
        name: 'Web Designing Modules',
        coursesHeading: 'WebDesign Module',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#f5b016',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
    {
        id: uuid.v4(),
        img: bussiness,
        name: 'Business Modules',
        coursesHeading: 'Business Module',
        core: 'Business Module',
        courseDis: 'Excel at doing what your passion is and only focus on perfecting it.',
        bgColor: '#f20f10',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
]


export const enrollData = {
    h1: 'Start a Journey. Enroll Now.',
    bgColor: 'bg-prussianBlue',
    color: 'white',
    p: 'learn something wherever you are',
    btnText: 'join a course now'
}

export const enrollData3 = {
    h1: 'Discover Your Future With US. Enroll Now.',
    bgColor: 'bg-white',
    color: 'prussianBlue',
    p: 'learn something wherever you are',
    btnText: 'join a course now'
}

export const enrollData2 = {
    h1: 'Learning Today. Leading Tomorrow. Enroll Now.',
    bgColor: 'bg-prussianBlue',
    color: 'white',
    p: 'learn something wherever you are',
    btnText: 'join a course now'
}

export const enrollData4 = {
    h1: 'Creative Thinking & Innovation. Enroll Now.',
    bgColor: 'bg-prussianBlue',
    color: 'white',
    p: 'learn something wherever you are',
    btnText: 'join a course now'
}

export const enrollData5 = {
    h1: 'Your bright future is our mission. Enroll Now.',
    bgColor: 'bg-prussianBlue',
    color: 'white',
    p: 'learn something wherever you are',
    btnText: 'join a course now'
}

export const fundamentalCoursesData2 = [
    {
        id: uuid.v4(),
        img: placement,
        name: 'Placement Modules',
        coursesHeading: 'Placement Module',
        core: 'Core Programming',
        courseDis: ' Success is the natural consequence of consistently applying the basic fundamentals.',
        bgColor: '#3478f6',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
    {
        id: uuid.v4(),
        img: cloudcomputing,
        name: 'Infrastructure Modules',
        coursesHeading: 'Infrastructure Modules',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#f20f10',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
    {
        id: uuid.v4(),
        img: testing,
        name: 'Testing Modules',
        coursesHeading: 'Testing Module',
        core: 'Testing Module',
        courseDis: 'Excel at doing what your passion is and only focus on perfecting it.',
        bgColor: '#f20f10',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
]

export const fundamentalCoursesData3 = [
    {
        id: uuid.v4(),
        img: placement,
        name: 'Data Science & Visualization',
        coursesHeading: 'Data Science & Visualization',
        core: 'Core Programming',
        courseDis: ' Success is the natural consequence of consistently applying the basic fundamentals.',
        bgColor: '#fd29c5',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
    {
        id: uuid.v4(),
        img: cloudcomputing,
        name: 'Cyber Security & Bitcoin Modules',
        coursesHeading: 'Cyber Security & Bitcoin',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#0eb582',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
    {
        id: uuid.v4(),
        img: webDesign,
        name: 'Trending Course',
        coursesHeading: 'Trending Course',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#7665ff',
        feature: 'feature',
        stream: 'Any Stream',
        ratings: 5,
        btnText: 'FOR MORE DETAILS'
    },
]



export const footerData = [
    {
        title: 'Popular Courses',
        lists: [
            { text: 'Full Stack Development' },
            { text: 'Data Science'},
            { text: 'Web APP'},
            { text: 'APP Development' },
            { text: 'Deep Learning' },
            { text: 'Python'}
        ]
    },
    {
        title: 'Quick Links',
        lists: [
            { text: 'Placement Modules' },
            { text: 'Business Modules' },
            { text: 'Web Designing Modules' },
            { text: 'Cyber Security Modules' },
            { text: 'Bitcoin Modules' },
            { text: 'Cryptocurrency Modules' },
            { text: 'Ethical Hacking Modules' }
        ]
    },
    {

        title: 'Contact Us',
        lists: [
            { text: '7208581498', icon: 'fas fa-phone' },
            { text: 'info@skillupitacademy.com', icon: 'ti ti-email' },
            { text: '807 Time Chambers S.V. ROAD Andheri (W)', icon: 'ti ti-location-pin' },
            { text: 'B-205 2nd Flr. Neelyog Square Ghatkopar (E)', icon: 'ti ti-location-pin' },
        ]
    },
]
export const footerBottomData = 'SkillUp IT Academy All Rights Reserved'


export const counterData = [
    {
        id: uuid.v4(),
        img: counter,
        name: 'counter',
        span: '100+ Topics',
        h3: 'LEARN ANYTHING',
        animate: 'fade-right'
    },
    {
        id: uuid.v4(),
        img: counter2,
        name: 'counter',
        span: '1900+ Students',
        h3: 'FUTURE GENIUS',
        animate: 'fade-up'
    },
    {
        id: uuid.v4(),
        img: counter3,
        name: 'counter',
        span: '15900 Tests Taken',
        h3: 'THATS A LOT',
        animate: 'fade-down'
    },
    {
        id: uuid.v4(),
        img: counter4,
        name: 'counter',
        span: '10+ Instructors',
        h3: 'ALL TRAINED PROFESSIONALS',
        animate: 'fade-left'
    },
]


export const counterData2 = [
    {
        img: counter,
        span: '216.67%',
        h3: 'MAX SALARY HIKE',
        animate: 'fade-right'
    },
    {
        img: counter2,
        span: '91.62%',
        h3: 'AVERAGE SALARY HIKE',
        animate: 'fade-up'
    },
    {
        img: counter3,
        span: '96%',
        h3: 'PLACEMENT SUCCESS RATE',
        animate: 'fade-down'
    },
    {
        img: counter3,
        span: '45',
        h3: 'NO. OF COMPANIES',
        animate: 'fade-down'
    },
]


export const contactFormData = {
    cancelIcon: 'X',
    head: 'Please enter your credentials',
    input: [
        {
            type: 'text',
            name: 'name',
        },
        {
            type: 'email',
            name: 'email',
        },
        {
            type: 'number',
            name: 'mobile',
        }
    ]
}

export const contactFormBtnData = 'submit'

export const overlayData = {
    logo: logoImg,
    pageNames: [
        { link: 'FundamentalModules' },
        { link: 'BusinessModules' },
        { link: 'WebDesigningModules' },
        { link: 'PlacementModules' },
        { link: 'InfrastructureModules' },
        { link: 'TestingModules' },
        { link: 'DataScience&Visualization' },
        { link: 'CyberSecurity&BitcoinModules' },
        { link: 'TrendingModules' }
    ]
}


// trending module --end

export const relatedModulesData = {
    arrows: true,
    autoplay: true,
    sliderData: [
        {
            img: course20,
            coursesHeading: 'MERN Stack Development',
            core: 'Open Source Solution',
            h1: 'MERN - JavaScript Framework',
            courseDis: 'Full Stack 3-Tier Application Development',
            // Duration: 'Duration : 3 months*',
            bgColor: '#3478f6',
            feature: 'feature',
            stream: 'Any Stream',
            ratings: 5,
            btnText: 'FOR MORE DETAILS'
        },
        {
            img: course21,
            coursesHeading: 'Full Stack Development - .NET',
            core: 'International Certification*',
            h1: 'Full Stack Development - .NET',
            courseDis: 'Cross Platform Development Solution',
            // Duration: 'Duration : 3 months*',
            bgColor: '#f5b016',
            feature: 'feature',
            stream: 'Any Stream',
            ratings: 5,
            btnText: 'FOR MORE DETAILS'
        },
        {
            img: course22,
            coursesHeading: 'Full Stack Modules - Python',
            core: 'International Certification*',
            h1: 'Full Stack Modules - Python',
            courseDis: 'AI-Based App Development Solution',
            // Duration: 'Duration : 3 months*',
            bgColor: '#f5b016',
            feature: 'feature',
            stream: 'Any Stream',
            ratings: 5,
            btnText: 'FOR MORE DETAILS'
        },
        {
            img: course23,
            coursesHeading: 'MEAN Stack Development',
            core: 'Open Source Solution',
            h1: 'MEAN - JavaScript Framework',
            courseDis: 'Cloud Ready Development Solution',
            // Duration: 'Duration : 3 months*',
            bgColor: '#f5b016',
            feature: 'feature',
            stream: 'Any Stream',
            ratings: 5,
            btnText: 'FOR MORE DETAILS'
        },
        {
            img: course24,
            coursesHeading: 'Stack Development - AI',
            core: 'Cutting Edge Solution',
            h1: 'DS, ML, DL Based Development Solution',
            courseDis: 'Cloud Ready Development Solution',
            // Duration: 'Duration : 5 months*',
            bgColor: '#fd29c5',
            feature: 'feature',
            stream: 'Any Stream',
            ratings: 5,
            btnText: 'FOR MORE DETAILS'
        },
    ]
}