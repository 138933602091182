import React from 'react'
import EnrollBody from './EnrollBody'
import EnrollFooter from './EnrollFooter'
import EnrollHeader from './EnrollHeader'
import EnrollLayout from './EnrollLayout'

export default function Enroll({ data }) {
    return (
        <EnrollLayout bgColor={data.bgColor}>
            <EnrollHeader color={data.color} h1={data.h1} />
            <EnrollBody p={data.p} />
            <EnrollFooter btnText={data.btnText} />
        </EnrollLayout>
    )
}
