import React from 'react'
import { footerIconsStyles } from '../Styles/Styles'

export default function FooterIcons(icon) {
    return (
        <span className={footerIconsStyles} >
            <i className={icon.icon}></i>
        </span>
    )
}
