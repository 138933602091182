import React from 'react'
import { container, layoutPaddings, textCenter } from '../Styles/Styles'

export default function EnrollLayout({ children, bgColor }) {
    return (
        <div className={`${bgColor}`}>
            <div className={`${container} ${layoutPaddings}`}>
                <div className={textCenter}>
                    {children}
                </div>
            </div>
        </div>
    )
}