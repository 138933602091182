import  { useContext } from 'react'
import Api from '../API/Api';
import { Context } from '../Context/Context';

const useContactModalHook = () => {
    const { state, dispatch } = useContext(Context);

    const contactFormModal = () => {
        dispatch({ type: 'TOGGLE_MODAL' })
    }
    const handleChange = (e) => {
        dispatch({ type: 'HANDLE_CHANGE', payload: e.target })
    }
    const { dataApi } = Api();

    const handleSubmit = (e) => {
        e.preventDefault();
        dataApi.post('/auth/postQueryData',
            {
                name: state.name,
                email: state.email,
                mobile: state.mobile,
            }).then(res => {
                console.log(res);
            }).catch(e => {
                console.log(e);
            })
        dispatch({type: "CLEAR_QUERY_STATE"})
    }

    return { state, contactFormModal, handleChange, handleSubmit }
}

export default useContactModalHook