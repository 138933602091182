import React from 'react'
import { contactAnchorData, loginAnchorData } from '../Data/SkillupData'
import { anchorStyles, contactAnchorStyles, loginAnchorStyles } from '../Styles/Styles'
import Anchor from './Anchor'

export default function Anchors() {
    return (
        <>
            <Anchor {...contactAnchorData} styles={`${anchorStyles}${contactAnchorStyles}`} />
            <Anchor {...loginAnchorData} styles={`${anchorStyles}${loginAnchorStyles}`} />
        </>
    )
}
