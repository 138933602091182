import React from 'react'
import CardsHeader from './CardsHeader';
import CardsBody from './CardsBody';
import CardsFooter from './CardsFooter';
import CardsHover from './CardsHover';
import { cardsLayout, dataAosDuration, dataAosFadeUp } from '../Styles/Styles';

export default function Cards({ data }) {
    return (
        <div
            data-aos={dataAosFadeUp}
            data-aos-duration={dataAosDuration}
            className={cardsLayout}
        >
            <CardsHeader
                img={data.image}
            />
            <CardsBody
                feature={data.feature}
                stream={data.stream}
                coursesHeading={data.courses_heading}
            />
            <CardsFooter
                core={data.core}
            />
            <CardsHover
                bgColor={data.bg_color}
                stream={data.stream}
                coursesHeading={data.courses_heading}
                courseDis={data.course_dis}
                name={data.name}
                ratings={data.ratings}
                btnText={data.btn_text}
            />
        </div >
    )
}
