import React from 'react'
import { dataAosDuration, dataAosFadeUp, enrollBodyStyle } from '../Styles/Styles'

export default function EnrollBody({ p }) {
    return (
        <>
            <p data-aos={dataAosFadeUp} data-aos-duration={dataAosDuration} className={enrollBodyStyle}>
                {p}
            </p>
        </>
    )
}
