import React from 'react'
import { NextBtn, PreviousBtn } from "../components/SliderButton";

export const useSliderSettings = (handleArrowClick) => {
    const settings = {
        autoplay: true,
        autoplaySpeed: 2800,
        dots: false,
        fade: true,
        arrows: true,
        prevArrow: <PreviousBtn handleArrowClick={() => handleArrowClick} />,
        nextArrow: <NextBtn handleArrowClick={() => handleArrowClick} />,
        beforeChange: handleArrowClick,
        responsive: [
            { breakpoint: 767, settings: { arrows: false } },
        ],
    };
    return { settings }
}
