import React from 'react'
import ContactFormInput from './ContactFormInput'
import Button from './Button'
import { contactFormBtnData } from '../Data/SkillupData'
import { buttonStyles, buttonStyles1 } from '../Styles/Styles'
import useContactModalHook from '../hooks/useContactModalHook'

export default function ContactForm({ data }) {
    const { handleSubmit } = useContactModalHook();
    return (
        <form onSubmit={handleSubmit}>
            <ul>
                {data.map((item, index) => (
                    <ContactFormInput item={item} id={index} />
                ))}
            </ul>
            <div className='flex justify-center'>
                <Button text={contactFormBtnData} styles={`${buttonStyles}${buttonStyles1}`} />
            </div>
        </form>
    )
}
