import React from 'react'
import { Link } from 'react-router-dom'
import {  overlayCloseBtnStyles } from '../Styles/Styles'

export default function CloseButton({ handleOverlayToggle }) {
    return (
        <Link className={ overlayCloseBtnStyles} onClick={() => handleOverlayToggle()}>
            x
        </Link>
    )
}
