import React from 'react'
import Button from './Button'
import { buttonStyles, buttonStyles1, slickTextContentStyle, slickTextContentSubTitleStyle, slickTextContentTitleStyle } from '../Styles/Styles'
import { slickBgBtn, slickBgHeaderData } from '../Data/SkillupData'
import HeaderStruct from './HeaderStruct'
import useContactModalHook from '../hooks/useContactModalHook'

export default function SlickSliderTextContent({ slide, date }) {
    const { contactFormModal } = useContactModalHook()
    return (
        <>
            <div className={`${slickTextContentStyle}`}>
                <h1 key={`${date}-h1`} data-aos="fade-up" data-aos-duration="800" className={slickTextContentTitleStyle}>
                    <span>
                        {slickBgHeaderData.map((item,id) => {
                            return <HeaderStruct key={id} item={item} />
                        })}
                    </span>
                </h1>
                <h2
                    key={`${date}-h2`}
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                    className={`custom-fade-up ${slickTextContentSubTitleStyle}`}
                >
                    {slide.title}
                </h2>
                <div key={`${date}-button`} data-aos="fade-up" data-aos-delay="400" data-aos-duration="800" className="custom-fade-up">
                    <Button styles={`${buttonStyles}${buttonStyles1}`}
                        onClick={contactFormModal}
                        text={slickBgBtn} />
                </div>
            </div>
        </>
    )
}
