import Aos from 'aos';
import { useEffect } from 'react'

const useAos = () => {
    useEffect(() => {
        Aos.init({
            offset: 100,
            once: true,
        });
    }, []);
}

export default useAos
