import React from 'react'
import FooterLayout from './FooterLayout'
import { footerBottomData } from '../Data/SkillupData'
import FooterListsLayout from './FooterListsLayout'
import FooterBottom from './FooterBottom'

export default function Footer({ data }) {
    console.log(data);
    return (
        <FooterLayout>
            {data.map(item => {
                // console.log(item.id);
                return <FooterListsLayout key={item.id} listData={item} />
            })}
            <FooterBottom footTitle={footerBottomData} />
        </FooterLayout>
    )
}
