import React from 'react'
import {aboutUsHeaderData } from '../Data/SkillupData'
import AboutUsCard from './AboutUsCard'
import Layout from './Layout'

export default function Courses({data}) {
    return (
        <Layout data={aboutUsHeaderData}>
            {data.map(item => (
                <AboutUsCard key={item.id} item={item} />
            ))}
        </Layout>
    )
}
