import React from 'react'
import { NextBtn, PreviousBtn } from "../components/SliderButton";

export const useSliderSettings2 = (handleArrowClick) => {
    const settings = {
        dots: false,
        arrows: true,
        prevArrow: <PreviousBtn handleArrowClick={() => handleArrowClick} />,
        nextArrow: <NextBtn handleArrowClick={() => handleArrowClick} />,
        infinite: true,
        fade:true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };
    return { settings }
}

