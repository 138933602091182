import React from 'react'
import useReadingHook from '../hooks/useReadingHook'
import { scrollProgressStyles, scrollWrapStyles } from '../Styles/Styles';

export default function ScrollWrap() {
    const completion = useReadingHook();

    return (
        <div className={scrollWrapStyles}>
            <div style={{ transform: `translateX(${completion - 100}%)` }} className={scrollProgressStyles}></div>
        </div>
    )
}
