import React from 'react'
// import CounterBgStyled from './CounterBgStyled'
import CounterLayout from './CounterLayout'
import CounterIcons from './CounterIcons'
import { CounterBgStyled } from '../Styles/Styles'

export default function CounterArea({ data }) {
    return (
        <CounterBgStyled>
            <CounterLayout>
                {data.map((item,index) => (
                    <CounterIcons key={item.id} index={index} data={item}/>
                ))}
            </CounterLayout>
        </CounterBgStyled>
    )
}
