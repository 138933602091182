import React from 'react'

export default function FooterBottom({ footTitle }) {
    return (
        <div className='pt-[25px] pb-[25px] border-t-[1px] border-t-white border-opacity-15 w-full'>
            <div className='container mx-auto'>
                <div className='text-center'>
                    <span className='text-base text-gray-500'>
                        {footTitle}
                    </span>
                </div>
            </div>
        </div>
    )
}
