import React from 'react'
import Slider from "react-slick";

export default function SliderWrap({ children, settings }) {
    return (
        <Slider {...settings}>
            {children}
        </Slider>
    )
}
