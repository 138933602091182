import React from 'react'
import { cardsFooterSpanStyle, cardsFooterStyle } from '../Styles/Styles'

export default function CardsFooter({ core }) {
    return (
        <div className={cardsFooterStyle}>
            <span className={cardsFooterSpanStyle}>
                {core}
            </span>
        </div>
    )
}
