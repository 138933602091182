import styled, { keyframes } from 'styled-components'
import img1 from "../img/counter/bg_img.png"
import img2 from "../img/bg3_img.png"
import img3 from '../img/bg_img.png'
import img4 from '../img/bg2_img.png'

//general 

export const flex = `flex`
export const relative = `relative`
export const dataAosFadeUp = `fade-up`
export const dataAosFadeRight = `fade-right`
export const dataAosFadeLeft = `fade-left`
export const dataAosDuration = `900`
export const dataAosDuration500 = `500`
export const dataAosRep = `false`
export const textCenter = `text-center`
export const layoutPaddings = `pt-[90px] pb-[65px]`
//general 

//Buttons --start

export const buttonStyles = `uppercase text-[#fff] inline-block leading-normal bg-gradient-to-r from-[#133282]
to-[#f54b8a] rounded-custom tracking-normal cursor-pointer transition-all duration-300 ease-in-out hover:rounded-customhover 
text-[14px] font-bold`

export const buttonStyles1 = ` py-[18px] px-[20px] sm:py-[16px] sm:px-[35px]`

export const buttonStyles2 = ` py-[16px] px-[5px]`

//Buttons --end

//Scrollwrap --start

export const scrollWrapStyles = `bg-prussianBlue h-[5px] fixed top-0 left-0 z-50 w-full`
export const scrollProgressStyles = `h-[5px] bg-pink`

//Scrollwrap --end

//Navbar --start
export const navBg = `bg-[#fff]`
export const navTopBg = `pt-7 pb-10 bg-prussianBlue`
export const navTopFlex = `container sm:flex mx-auto justify-between px-[15px]`
export const navTopOuter = `mr-35 relative pr-30`
export const navTopPara = `inline-block m-0 text-[#fff] text-[13px] sm:text-[14px]`
export const navTopIcon = `fas fa-phone mr-[5px] fa-rotate-270`
export const navTopLinkOuter = `text-center sm:text-right`
export const navTopUl = `m-0 p-0`
export const navTopLinks = `inline-block leading-normal`
export const navTopAnchor = `text-[14px] text-[#fff] ml-[19px] pl-[19px] relative`
export const navBottomWrap = `container flex justify-between mx-auto items-center`
export const navLogoWrap = `py-[5px] px-[15px]`
export const navLogo = `max-sm:w-3/4 w-72`
export const navSloganWrap = `mb-2 text-[18px]`
export const navSloganH5 = `inline-block`
export const navSlogaSpan = `max-sm:hidden`
export const navButtonWrap = `hidden md:block mr-10`
export const navIconWrap = `w-20 mr-5`
export const navIconsStyles = `text-[30px] float-right cursor-pointer`

//Navbar --end

//Modal --start

export const modalLayoutStyles = `fixed inset-0 flex items-center justify-center z-10`
export const modalOuterStyles = `h-auto rounded-xl w-full lg:w-2/5`
export const modalInnerStyles = `border-0 shadow-2xl relative flex flex-col min-w-0 bg-white bg-clip-border rounded p-6`
export const modalDelay = `500`
export const modalHead = `mb-2`
export const modalHeadButton = `absolute right-4 top-2 font-bold`
export const modalHeadText = `text-blue font-bold text-2xl`
export const modalList = `mb-4`
export const modalLabel = `text-[#7e7e7e] cursor-pointer text-sm font-normal capitalize`

//Modal --end

//Anchor --start
export const anchorStyles = `fixed z-20 bg-prussianBlue text-white font-black py-[0.2rem]
px-5 rounded-t-lg rotate-z-270 md:hidden`

export const contactAnchorStyles = ` bottom-[58%] -right-10`

export const loginAnchorStyles = ` bottom-[42%] -right-[1.6rem]`

//Anchor --end

//slickSlider --start

export const sliderBgWrapStyle = { border: '1px solid #000', width: '100%', height: '480px' }
export const slickTextContentStyle = `container mx-auto pt-[49px] pb-[120px] md:pt-[62px] md:pb-[120px] lg:pt-[120px] lg:pb-[129px]`
export const slickTextContentTitleStyle = `custom-fade-up text-[34px] leading-[43px] md:text-[57px] md:leading-[85px]`
export const slickTextContentSubTitleStyle = `text-black text-[35px] mb-4 leading-tight`
export const fontBold = `font-bold`

//slickSlider --end

//layout --start 

export const layoutWrap = `mt-20 pb-[70px]`
export const container = `container mx-auto`
export const layoutH1Wrap = `mb-[70px] text-center`
export const layoutH1 = `font-proxima  leading-[72px] tracking-tighter text-4xl`
export const layoutWrapFlex = `flex flex-wrap justify-start`

//layout --end

//AboutUs --start

export const aboutUsCardLayout = `mb-12 mx-6 border-black max-w-[550px]`
export const aboutUsCardLayoutInner = `relative border-[1px] border-solid border-[#dedfe2] mr-[2px] pb-[5%] rounded-[15px] shadow-[10px_5px_5px]
shadow-gray-300 transition duration-[400s] hover:border-none hover:opacity-100 hover:shadow-[10px_5px_5px] hover:shadow-[#C0C0C0]
pl-[30px] pt-[30px] pr-[27px] pb-[24px] `
export const aboutUsCardH1 = `m-0 text-[#002147] leading-10 tracking-[0.15px] border-b border-b-solid
border-b-pink text-justify border-t-[1px] border-t-blue font-sans text-[1.2rem] mt-[20px] pt-[15px] mb-[19px] pb-4 visible font-normal`
export const aboutUsSpan = `text-2xl`
export const iconFirstLi = `max-w-[51px] mr-[15px] float-left`
export const iconImg = `border border-solid border-[#002147] rounded-full`
export const iconPTag = `text-[18px]`
//AboutUs --end

//Cards --start

export const cardsLayout = `A group relative w-full mx-4 mb-[4%] md:w-[350px] sm:max-md:h-[600px] lg:w-[290px] xl:w-[370px] xl:mx-[15px]
border-[1px] border-solid border-[#dedfe2]  pb-[1%] rounded-[15px] shadow-[10px_5px_5px] shadow-gray-300 transition duration-500`
export const cardsHeaderWrap = `h-[230px]`
export const cardsHeaderLinkImageStyle = `relative h-full w-full rounded-tl-[15px] rounded-tr-[15px]`
export const cardsHeaderIconWrapStyle = `top-[10px] right-auto absolute left-[25px]`
export const cardsBadgeStyle = `relative bg-[#fed619] h-[35px] leading-9 my-0 mx-[5px] text-center w-[35px] bg-60 float-left bg-center bg-no-repeat rounded-full`
export const cardsBadgeImgStyle = `absolute inset-0 m-auto bg-no-repeat bg-center bg-[15px/23px] w-[15px] h-[23px]`
export const cardsBodyWrap = `pl-[30px] pt-[30px] pr-[27px] pb-[24px]`
export const cardsFeatureBadgeStyle = `mb-[3%] text-left`
export const cardsFeatureBadgeSpanStyle = `leading-none text-xs font-roboto text-[#002147] uppercase font-medium bg-[#fdc800] py-[9px] px-[15px] 
rounded-[3px] inline-block transition duration-300`
export const cardsFeatureBadgeSpanImgStyle = `bg-center inline w-[10px] h-[11px] my-auto mr-[4px] bg-no-repeat align-baseline`
export const cardsBodyPTagStyle = `text-left`
export const cardsBodyH1TagStyle = `text-[22px] leading-none m-0 text-[#002147] mt-[26px] border-b border-solid border-b-[#f0f0f0] pb-[15px] mb-[19px] 
  font-medium font-roboto text-left`
export const cardsFooterStyle = `pr-[23px] pl-[25px] pt-[10px] pb-[15px]`
export const cardsFooterSpanStyle = `mr-[20px] text-[14px] bg-[#002147] text-[#fff] pt-[6px] px-[15px] pb-[5px] rounded-[50px] inline-block`
export const cardsHoverStyle = `absolute top-0 h-full w-full z-0 py-[10px] px-[30px] rounded-[15px] max-xtrasmall:text-center lg:max-xl:text-center 
pt-16 opacity-0 group-hover:opacity-100  transition-opacity duration-500 ease-in-out custom-transform`
export const cardsHoverPtagStyle = `bg-white bg-opacity-20 rounded-lg inline-block p-1.5 px-2.5 text-white text-left transition
duration-500 text-[13px] sm:text-[14px] `
export const cardsHoverHeadingStyle = `mt-[5%] font-extrabold text-[#fff] text-left text-[25px] sm:text-[30px] md:max-lg:text-[29px]
md:max-lg:mr-[20px]`
export const cardsHoverCourseDisStyle = `mt-[10%] text-[#fff] text-left border-b-[1px] border-b-solid border-white border-opacity-20  
font-roboto leading-[30px] max-xtrasmall:mb-[8%] mb-[25%] lg:mb-[10%] text-[16px] h-[150px] sm:text-[19px]`
export const cardsHoverFooterStyle = `flex max-xtrasmall:flex-col max-xtrasmall:text-center justify-between lg:flex-col lg:text-center
xl:flex-row xl:justify-between`
export const cardsHoverStarsWrapStyle = `w-1/2 mb-[10%] float-left max-xtrasmall:mx-auto max-xtrasmall:justify-center lg:w-auto lg:mx-auto flex
 lg:justify-center xl:justify-start xl:mx-0`
export const cardsHoverStarsStyle = `fas fa-star pt-[5%] text-[#fdc800]`
//Cards --end

//Enroll --start

export const enrollHeaderStyle = `max-sm:text-[30px] text-[48px]`
export const enrollBodyStyle = `text-[14px] tracking-[1px] sm:text-[17px] sm:tracking-[3px] text-center uppercase mb-[30px]`

//Enroll --start

//counter --start
const shrink = keyframes`
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
`;

export const CounterBgStyled = styled.div`
    background-color: #fafafa;
    background-image: url(${img1}), url(${img2});
    background-repeat: no-repeat;
    background-position: left;
    animation: ${shrink} 2s infinite alternate;
`;

export const CounterBgStyled1 = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index:9;
  top:0;
  left:0;
  overflow-x: hidden;
  transition: width 1s;
  background-color: #fff;
  background-image: url(${img3}), url(${img4});
  background-repeat: no-repeat;
  background-position: left;
  animation: ${shrink} 3s infinite alternate;
`;

export const counterFlex = `flex flex-wrap justify-evenly`
export const counterIcons = `group pt-[70px] px-[20px] pb-[60px] w-[300px] sm:w-[200px] md:w-[300px] lg:w-[200px]
lg:h-[250px] xl:w-[250px] rounded-[50%] text-center  hover:shadow-custom hover:bg-pink hover:text-white transition duration-500`
export const counterImageStyles = `mb-[24px] w-[25%] mx-auto`
export const counterSpanStyles = `block text-xl font-bold tracking-tighter word-spacing-widest`
export const counterH3Styles = `group-hover:text-white m-0 text-[12px] block leading-none font-thin font-sans text-[#8a8a8a] mt-[9px] 
tracking-wider  transition duration-500 `
//counter --end

//footer --start

export const footerLayoutStyles = `bg-prussianBlue pt-[150px]`
export const footerLayoutFlexStyles = `flex flex-wrap justify-evenly md:justify-start lg:justify-center xl:justify-evenly  
max-sm:pl-[10px]`
export const footerListLayoutStyles = `mb-[30px] w-full md:w-[370px] lg:w-[300px] xl:w-[370px]`
export const footerListLayoutH1Styles = `text-2xl text-yellow-400 font-bold leading-none mr-0 mb-8`
export const footerListStyles = `text-base text-white text-opacity-65 p-0 block mb-[14px] leading-none`
export const footerListLinksStyles = `hover:text-yellow-400 hover:opacity-100`
export const footerIconsStyles = `text-sm text-yellow-400 mr-2`
//footer --end

//Overlay --start

export const overlayCloseBtnStyles = `absolute -top-[3px] right-11 text-[50px] text-blue`
export const overlayContentStyles = `rounded-[3px] mb-0 mt-0 p-30`
export const overlayLinksWrapStyles = `mb-[30px] ml-5`
export const overlayH4Styles = `text-24`
export const overlayLinksStyles = `text-[#fff] block leading-normal font-bold bg-gradient-to-r from-[#133282] to-[#f54b8a] rounded-custom tracking-normal
cursor-pointer transition-all duration-300 ease-in-out hover:rounded-customhover p-[2%] w-3/4 text-[22px] mx-auto max-md:text-[14px] `
//Overlay --end
