import React from 'react'
import { dataAosDuration, dataAosFadeUp, enrollHeaderStyle } from '../Styles/Styles'

export default function EnrollHeader({ color, h1 }) {
    return (
        <h1 data-aos={dataAosFadeUp} data-aos-duration={dataAosDuration} className={`text-${color} ${enrollHeaderStyle}`}>
            {h1}
        </h1>
    )
}
