import React from 'react'
import { buttonStyles, buttonStyles1, dataAosDuration, dataAosFadeLeft } from '../Styles/Styles'
import Button from './Button'

export default function EnrollFooter({ btnText }) {
    return (
        <div data-aos={dataAosFadeLeft} data-aos-duration={dataAosDuration}>
            <Button styles={`${buttonStyles}${buttonStyles1}`} text={btnText} />
        </div>
    )
}
