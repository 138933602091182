import React, { useContext } from "react";
// import Slider from "react-slick";
import SliderWrap from './SliderWrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickSliderBackground from "./SlickSliderBackground";
import { useDateHook } from "../hooks/useDateHooks";
import { useSliderSettings } from "../hooks/useSliderSettings";
import { relative } from "../Styles/Styles";
import { Context } from "../Context/Context";
import useDisplayHooks from "../hooks/useDisplayHooks";

export default function SlickSlider({ data }) {
    const { date, handleArrowClick } = useDateHook();
    const { state } = useContext(Context);
    const { settings } = useSliderSettings(handleArrowClick)
    const name = state.overlayState.name
    const displayData = useDisplayHooks(data,name)

    return (
        <div className={`${relative} slider-area slider-active`}>
            <SliderWrap settings={settings}>
                { displayData.map((slide, index) => (
                    <SlickSliderBackground key={slide.id} slide={slide} index={index} date={date} />
                ))}
            </SliderWrap>
        </div>
    );
}

