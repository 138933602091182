import React from 'react'
import Layout from './Layout'
import Cards from './Cards'
import useDisplayHooks from '../hooks/useDisplayHooks';
// import useSliderData from '../hooks/useSliderData';

export default function FundamentalCourses({ data, layoutData, name }) {
    console.log(data);
    const hookData = useDisplayHooks(data, name);
    const displayData = name ? hookData : data;
    return (
        <>
            <Layout data={layoutData}>
                {displayData.map((item, index) => {
                    return <Cards key={item.id} data={item} index={index} />
                })}
            </Layout>
        </>
    )
}
