import { useContext, useEffect } from "react";
import Api from "../API/Api";
import { Context } from "../Context/Context";

const useFetchData = (url, type) => {
    const { dataApi } = Api();
    const { dispatch } = useContext(Context);
   
    useEffect(() => {
        dataApi.get(url).then(res => {
            dispatch({ type: type, payload: res.data });
            console.log(res);
        }).catch(e => {
            console.log(e);
        })
    }, []);
}

export default useFetchData;