import React from 'react'
import { Link } from 'react-router-dom'
import { navTopAnchor, navTopLinks } from '../Styles/Styles'

const HeaderLinks = ({ index, to, icon, styles }) => {
    return (
        <li key={index} className={navTopLinks}>
            <Link to={to} className={`${navTopAnchor} ${styles}`}
            >
                <span className={icon}></span>
            </Link>
        </li>
    )
}

export default HeaderLinks