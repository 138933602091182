import React from 'react'
import { ContextProvider } from './Context/Context'
import useAos from './hooks/useAos';
import useScrollToTop from './hooks/useScrollToTop'
import Links from './components/Links';

export default function App() {
  useScrollToTop();
  useAos()
  return (
    <div className='overflow-x-hidden'>
      <ContextProvider >
        <Links />
      </ContextProvider>
    </div>
  )
}
