import React from 'react'
import { CSSTransition } from 'react-transition-group';
import ContactModalLayout from './ContactModalLayout';
import ContactForm from './ContactForm';
import ContactHead from './ContactHead';
// import { Context } from '../Context/Context';
import useContactModalHook from '../hooks/useContactModalHook';

export default function ContactModal({ data }) {
    const { state, contactFormModal,  } = useContactModalHook()

    return (
        <CSSTransition
            in={state.showModal}
            timeout={500}
            classNames="modal"
            unmountOnExit
        >
            <ContactModalLayout >
                <ContactHead data={data} contactFormModal={contactFormModal} />
                <ContactForm data={data.input} />
            </ContactModalLayout>
        </CSSTransition >
    );
}
