import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Api from '../API/Api'
import badge from "../img/badge.png"
import certificate from "../img/certificate.png"

import { cardsBadgeImgStyle, cardsBadgeStyle, cardsHeaderIconWrapStyle, cardsHeaderLinkImageStyle, cardsHeaderWrap } from '../Styles/Styles'

export default function CardsHeader({ img }) {
    const { imagePre } = Api();
    return (
        <div className={cardsHeaderWrap}>
            <Link>
                <LazyLoadImage src={`${imagePre}${img}`} alt='fundamentalImg' className={cardsHeaderLinkImageStyle} />
            </Link>
            <div className={cardsHeaderIconWrapStyle}>
                <span className={cardsBadgeStyle}>
                    <img src={badge} alt='badge' className={cardsBadgeImgStyle} />
                </span>
                <span className={cardsBadgeStyle}>
                    <img src={certificate} alt='certificate' className={cardsBadgeImgStyle} />
                </span>
            </div>
        </div>
    )
}
