import React from 'react'
import { navBg } from '../Styles/Styles'

const NavWrap = ({ children }) => {
    return (
        <header id='Home'>
            <div className={navBg}>
                {children}
            </div>
        </header>
    )
}

export default NavWrap