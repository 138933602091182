import React from 'react'
import { Link } from 'react-router-dom'
import { IconData } from '../Data/SkillupData'
import Icon from './Icon'
import star from "../img/star.png"
import { cardsBodyH1TagStyle, cardsBodyPTagStyle, cardsBodyWrap, cardsFeatureBadgeSpanImgStyle, cardsFeatureBadgeSpanStyle, cardsFeatureBadgeStyle } from '../Styles/Styles'

export default function CardsBody({ feature, stream, coursesHeading }) {
    return (
        <div className={cardsBodyWrap}>
            <div className={cardsFeatureBadgeStyle}>
                <span className={cardsFeatureBadgeSpanStyle}
                >
                    <img src={star} alt='star' className={cardsFeatureBadgeSpanImgStyle} />
                    {feature}
                </span>
            </div>
            <p className={cardsBodyPTagStyle}>{stream}</p>
            <div>
                <h1 className={cardsBodyH1TagStyle}>
                    <Link>
                        {coursesHeading}
                    </Link>
                </h1>
            </div>
            <Icon data={IconData} />
        </div>
    )
}
