import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const PreviousBtn = ({ className, onClick, handleArrowClick }) => (
    <div className={className} onClick={() => { onClick(); handleArrowClick(); }}>
        <ArrowBackIosNewIcon />
    </div>
);

export const NextBtn = ({ className, onClick, handleArrowClick }) => (
    <div className={className} onClick={() => { onClick(); handleArrowClick(); }}>
        <ArrowForwardIosIcon />
    </div>
);