import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { flex, iconFirstLi, iconImg, iconPTag } from '../Styles/Styles'

export default function Icon({ data }) {
    return (
        <ul className={flex}>

            <li className={iconFirstLi}>
                <LazyLoadImage src={data.img} alt={data.logo} className={iconImg} />
            </li>
            <li>
                <p className={iconPTag}>{data.title}</p>
            </li>
        </ul>
    )
}
