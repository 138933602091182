import React from 'react'
import { container, footerLayoutFlexStyles, footerLayoutStyles } from '../Styles/Styles'

export default function FooterLayout({ children }) {
    return (
        <div className={footerLayoutStyles}>
            <div className={container}>
                <div className={footerLayoutFlexStyles}>
                    {children}
                </div>
            </div>
        </div>
    )
}
