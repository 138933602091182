import { useEffect, useRef, useState } from "react";

const useFixedHook = () => {
    const [isFixed, setIsFixed] = useState(false);
    const headRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 300) {
                setIsFixed(true);
            } else {
                setIsFixed(false)
            }
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    return { isFixed, headRef }
}

export default useFixedHook
