import React from 'react'
import { Link } from 'react-router-dom'
import { footerListLinksStyles, footerListStyles } from '../Styles/Styles'
import FooterIcons from './FooterIcons'

export default function FooterLists({ list, id }) {
    // console.log(list.icon);
    return (
        <li key={id} className={footerListStyles}>
            <FooterIcons icon={list.icon} />
            <Link className={footerListLinksStyles}>
                {list.text}
            </Link>
        </li>
    )
}
