import React from 'react'
import useContactModalHook from '../hooks/useContactModalHook';
import { modalLabel, modalList } from '../Styles/Styles';

export default function ContactFormInput({ item, id }) {
    const { state, handleChange } = useContactModalHook()

    return (
        <li key={id} className={modalList}>
            <label htmlFor={item.name} className={modalLabel}>
                {item.name}:
            </label>
            <input
                type={item.type}
                className='block w-full py-[0.375rem] px-[0.75rem] text-base leading-6 
                    text-[#495057] bg-white bg-clip-padding border border-[#ced4da]'
                id={item.name}
                name={item.name}
                value={state[item.name] || ''}
                onChange={handleChange}
                required
            />
        </li>
    )
}
