import React from 'react'

export default function OverlayLayout({ children }) {
    return (
        <div className='relative top-[10%] w-full text-center'>
            <div className='container mx-auto '>
                <div className='flex flex-col'>
                    {children}
                </div>
            </div>
        </div>
    )
}
