import React from 'react'
import Api from '../API/Api'
import { sliderBgWrapStyle } from '../Styles/Styles'
import SlickSliderTextContent from './SlickSliderTextContent'

export default function SlickSliderBackground({ slide, date }) {

    const { imagePre } = Api()
    return (
        <div className={sliderBgWrapStyle}>
            <div
                style={{
                    backgroundImage: `linear-gradient(
                        to right,
                        rgba(255, 255, 255, 0.5),
                        rgba(255, 255, 255, 0.1)
                    ), url(${imagePre}${slide.image})`,
                    width: '100%',
                    height: '480px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundSize: "cover",
                    backgroundPosition: 'center',
                    overflowX: 'hidden',
                }}
            >
                <SlickSliderTextContent slide={slide} date={date} />
            </div>
        </div>
    )
}
