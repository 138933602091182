import React, { useContext } from 'react'
import { Context } from '../Context/Context'
import useOverlay from '../hooks/useOverlay';
import { CounterBgStyled1 } from '../Styles/Styles'
import CloseButton from './CloseButton'
import OverlayContent from './OverlayContent';

export default function Overlay({ data }) {
    const { state } = useContext(Context);
    const { handleOverlayToggle } = useOverlay()

    if (!state.overlayToggle) return null;

    return (
        <CounterBgStyled1>
            <CloseButton handleOverlayToggle={handleOverlayToggle} />
            <OverlayContent data={data} handleOverlayToggle={handleOverlayToggle}  />
        </CounterBgStyled1>
    )
}
