import React from 'react'
import { container, counterFlex, layoutPaddings } from '../Styles/Styles'

export default function CounterLayout({ children }) {
    return (
        <div className={`${container} ${layoutPaddings}`}>
            <div className={counterFlex}>
                {children}
            </div>
        </div>
    )
}
