import React from 'react'
import { useDateHook } from '../hooks/useDateHooks';
import { useSliderSettings2 } from '../hooks/useSliderSettings2';
import FundamentalCourses from './FundamentalCourses'
import SliderWrap from './SliderWrap'
import useChunkData from '../hooks/useChunkData';

export default function RelatedCourses({ data, layoutData }) {
    const { handleArrowClick } = useDateHook();
    const { settings } = useSliderSettings2(handleArrowClick)

    console.log(data);
    
    return (
        <div className="relative slider-area slider-active">
            <SliderWrap settings={settings}>
                {useChunkData(data, 3).map((chunk, index) => (
                    <div key={index} >
                        <FundamentalCourses data={chunk} layoutData={layoutData} />
                    </div>
                ))}
            </SliderWrap>
        </div>
    )
}
