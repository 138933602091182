import React from 'react'
import { IconData } from '../Data/SkillupData';
import Icon from './Icon';
import { aboutUsCardH1, aboutUsCardLayout, aboutUsCardLayoutInner, aboutUsSpan, dataAosDuration, dataAosFadeRight, dataAosFadeUp } from '../Styles/Styles';

export default function AboutUsCard({ item }) {
    return (
        <div className={aboutUsCardLayout} data-aos={dataAosFadeUp} data-aos-duration={dataAosDuration} >
            <div className={aboutUsCardLayoutInner} >
                <Icon data={IconData} />
                <h1 data-aos={dataAosFadeRight} data-aos-duration={dataAosDuration} className={aboutUsCardH1}>
                    <span className={aboutUsSpan}>{item.h1_span}</span>
                    {item.h1}
                </h1>
                <Icon data={IconData} />
            </div>
        </div>
    )
}
