import React from 'react'
import { Link } from 'react-router-dom'
import { buttonStyles, buttonStyles2, cardsHoverCourseDisStyle, cardsHoverFooterStyle, cardsHoverHeadingStyle, cardsHoverPtagStyle, cardsHoverStyle, cardsHoverStarsWrapStyle, cardsHoverStarsStyle } from '../Styles/Styles'
import Button from './Button'

export default function CardsHover({ bgColor, stream, coursesHeading, courseDis, name, ratings, btnText }) {
    return (
        <div style={{ backgroundColor: bgColor }} className={cardsHoverStyle}>

            <p className={cardsHoverPtagStyle}
            >
                {stream}
            </p>
            <h3 className={cardsHoverHeadingStyle}>
                {coursesHeading}
            </h3>
            <p className={cardsHoverCourseDisStyle}>
                {courseDis}
            </p>
            <div className={cardsHoverFooterStyle}
            >
                <div className={cardsHoverStarsWrapStyle}>
                    {Array.from({ length: ratings }).map((_, index) => (
                        <i
                            className={cardsHoverStarsStyle}
                            id={`star${index + 1}`}
                            key={index}
                        >
                        </i>
                    ))}
                </div>
                <div>
                    <Link to={`/pages/${name}`}>
                        <Button styles={`${buttonStyles}${buttonStyles2}`} text={btnText} />
                    </Link>
                </div>

            </div>
        </div>
    )
}
