import React from 'react'
import { counterH3Styles, counterIcons, counterImageStyles, counterSpanStyles, dataAosDuration } from '../Styles/Styles'

export default function CounterIcons({ data, index }) {
    return (
        <div key={index} data-aos={data.animate} data-aos-duration={dataAosDuration} >
            <div className={counterIcons}>
                <img src={data.img} alt={data.name} className={counterImageStyles } />
                <span className={counterSpanStyles}>
                    {data.span}
                </span>
                <h3 className={counterH3Styles}>
                    {data.h3}
                </h3>
            </div>
        </div>
    )
}
